import * as React from 'react';
import { useEffect, useState } from 'react';
import { ContentService, Logger } from '../../@types/feature-service-types';
import { CmsContent, normalizeContent } from '../editor';
import { loadStoryStreamScript } from '../utils';

export interface StoryStreamProps {
  featureAppId: string;
  logger: Logger;
  contentService: ContentService;
}

export const StoryStream: React.FC<StoryStreamProps> = ({
  featureAppId,
  logger,
  contentService,
}) => {
  const [editorContent, setEditorContent] = React.useState<ReturnType<typeof normalizeContent>>(
    normalizeContent(contentService.getContent() as CmsContent),
  );

  // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-member-access
  const [appId, setAppId] = useState<string | undefined>(editorContent?.storyStreamClientId);
  const [elementId, setElementId] = useState<string>(
    editorContent?.storyStreamElementId || 'stry-wrapper',
  );

  // This is done to dynamically update the app based on configuration changes
  // and is more or less only done in the demo
  useEffect(() => {
    contentService.onContentChange(() => {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      const newEditorContent = contentService.getContent();
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-member-access
      setAppId(newEditorContent?.storyStreamClientId);
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-member-access
      setElementId(newEditorContent?.storyStreamElementId || 'stry-wrapper');
    });
  }, [contentService]);

  useEffect(() => {
    const listener = () => {
      setEditorContent(normalizeContent(contentService.getContent() as CmsContent));
    };
    contentService.onContentChange(listener);

    return () => {
      contentService.removeOnContentChange(listener);
    };
  }, [contentService, setEditorContent]);

  useEffect(() => {
    const script = loadStoryStreamScript(document, featureAppId, logger, appId);
    return () => {
      if (script && appId === script.id) {
        document.body.removeChild(script);
        logger.info(`Removed StoryStream script for ${featureAppId}`);
      }
    };
  }, [appId, featureAppId, logger]);

  return <div id={elementId} className="digital-magazine" />;
};
