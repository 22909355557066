import { Logger } from '../../@types/feature-service-types';

export const loadStoryStreamScript = (
  document: Document,
  featureAppId: string,
  logger: Logger,
  appId?: string,
): HTMLScriptElement | null => {
  const foundScriptTags = document.querySelectorAll(`script[data-featureapp*="${featureAppId}"]`);

  if (foundScriptTags.length > 0) {
    logger.info(`StoryStream script already added for ${featureAppId}`);
    return null;
  }

  if (!appId || appId.length === 0) {
    logger.error(`The StoryStream Client/App ID for ${featureAppId} is undefined or empty`);
    return null;
  }

  const script = document.createElement('script');

  script.id = appId;
  script.dataset.featureapp = featureAppId;
  script.src = `https://apps.storystream.ai/app/js/${appId}.js`;
  script.async = true;

  document.body.appendChild(script);
  return script;
};
