/**
 * This is the entry point for Feature Hub App integration
 */
import React from 'react';
import { FeatureAppDefinition, FeatureAppEnvironment, FeatureServices } from '@feature-hub/core';
import { ReactFeatureApp } from '@feature-hub/react';
import type { Logger } from '@feature-hub/logger';
import type { ContentServiceV1 } from '@oneaudi/content-service';
import { RenderModeServiceV1 } from '@oneaudi/render-mode-service';
import App from './FeatureApp';

export interface FeatureServiceDependencies extends FeatureServices {
  readonly 's2:logger'?: Logger;
  readonly 'audi-content-service': ContentServiceV1;
  readonly 'audi-render-mode-service'?: RenderModeServiceV1;
}

const featureAppDefinition: FeatureAppDefinition<ReactFeatureApp, FeatureServiceDependencies> = {
  dependencies: {
    featureServices: {
      's2:logger': '^1.0.0',
      'gfa:locale-service': '^1.0.0',
      'audi-content-service': '^1.0.0',
    },
    externals: {
      'styled-components': '*',
      '@feature-hub/react': '^3.5.0',
      react: '^18.2.0',
      'react-dom': '^18.2.0',
      axios: '^0.27.2',
    },
  },
  optionalDependencies: {
    featureServices: {
      's2:logger': '*',
      'audi-render-mode-service': '^1.0.0',
    },
  },

  create: ({
    featureServices,
    featureAppId,
  }: FeatureAppEnvironment<FeatureServiceDependencies, void>) => {
    const loggerService = featureServices['s2:logger'] || console;
    loggerService?.info('Social Stream Feature App created.');

    const contentService = featureServices['audi-content-service'];
    const renderModeService = featureServices['audi-render-mode-service'];

    return {
      render: () => {
        return (
          <App
            contentService={contentService}
            renderModeService={renderModeService}
            featureAppId={featureAppId}
            loggerServices={loggerService}
          />
        );
      },
    };
  },
};

export default featureAppDefinition;
